import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { multimediaContentItemContent$key } from "scmp-app/queries/__generated__/multimediaContentItemContent.graphql";

import { getLabel } from "./helpers";
import {
  Container,
  Content,
  CoverImage,
  GalleryContainer,
  GalleryTotal,
  Headline,
  Label,
  StyledEntityLink,
  StyledGalleryIcon,
} from "./styles";

export type Props = {
  reference: multimediaContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment multimediaContentItemContent on Content {
          entityId
          urlAlias
          ... on Gallery {
            images {
              __typename
            }
          }
          ...entityLink
          ...helpersGetLabelContent
          ...hooksContentItemProvidersContent
            @arguments(
              withHeadline: true
              withCoverImage: true
              withCoverImageSize540x360: true
              withCoverImageSize1200x800: true
              withVideoDurationOverlay: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    const label = getLabel(content);

    const galleryTotalImages = content?.images?.length;

    const isVideo = label === "Video";

    return (
      <Container className={className} ref={reference}>
        <StyledEntityLink
          reference={content}
          onClick={() => onClick?.(content.entityId, content.urlAlias)}
        >
          <Content>
            <Label>{label}</Label>
            <Headline>
              {providers.headline({
                hideTooltip: true,
                preferSocialHeadline: true,
                skipHeadlineSponsorStyle: true,
              })}
            </Headline>
          </Content>
          <CoverImage $isVideo={isVideo}>
            {providers.coverImage({
              responsiveVariants: {
                desktopUp: "size540x360",
                mobileUp: "size1200x800",
                tabletUp: "size540x360",
              },
              withCaption: false,
            })}
            {providers.video?.durationOverlay({
              variant: "homepage",
            })}
            {galleryTotalImages && (
              <GalleryContainer>
                <StyledGalleryIcon />
                <GalleryTotal>{galleryTotalImages}</GalleryTotal>
              </GalleryContainer>
            )}
          </CoverImage>
        </StyledEntityLink>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeMultimedia";

export const ContentItemMultimedia = withHighlightedHeadline(Component);
