import styled from "@emotion/styled";
import { fontRobotoCondensed } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemMultimedia } from "scmp-app/components/content/content-item-render/variants/multimedia";
import { ContentItemMultimediaPodcast } from "scmp-app/components/content/content-item-render/variants/multimedia-podcast";

export const StyledContentItemMultimedia = styled(ContentItemMultimedia)``;
export const StyledContentItemMultimediaPodcast = styled(ContentItemMultimediaPodcast)``;

export const Container = styled.div`
  padding: 20px;

  border-radius: 8px;

  outline: 1px solid rgba(0, 0, 0, 0.2);
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    max-inline-size: 300px;
  }

  ${StyledContentItemMultimedia}:not(:first-child), ${StyledContentItemMultimediaPodcast}:not(:first-child) {
    border-block-start: 1px solid rgba(0, 0, 0, 0.1);

    margin-block-start: 20px;
    padding-block-start: 20px;
    ${props => props.theme.breakpoints.up("tablet")} {
      margin-block-start: 0;
      border-block-start: 0;

      padding-block-start: 0;
    }
    ${props => props.theme.breakpoints.up("homeDesktop")} {
      margin-block-start: 20px;
      padding-block-start: 20px;
      border-block-start: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Title = styled(BaseLink)`
  margin-block-end: 20px;

  color: rgb(0, 0, 0, 0.5);
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  line-height: 23px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 20px;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: column;
    gap: 0;
  }
`;
