import { notEmpty, useTrackImpressionEventByIntersection } from "@product/scmp-sdk";
import first from "lodash/first";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import type { RecirculationWidgetImpressionEvent } from "scmp-app/components/tracking/google-analytics-4/types";
import { tracking } from "scmp-app/data";
import { getAbsoluteUrl } from "scmp-app/lib/utils";
import type { multimediaQuery$key } from "scmp-app/queries/__generated__/multimediaQuery.graphql";

import {
  Container,
  Content,
  StyledContentItemMultimedia,
  StyledContentItemMultimediaPodcast,
  Title,
} from "./styles";

type Props = {
  className?: string;
  reference: multimediaQuery$key;
};

export const Multimedia: FunctionComponent<Props> = ({ className, reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment multimediaQuery on Query
      @argumentDefinitions(multimediaQueueName: { type: "String!" }) {
        multimedia: queue(filter: { name: $multimediaQueueName }) {
          items(first: 4) {
            edges {
              node {
                __typename
                ... on Article {
                  types {
                    value {
                      name
                    }
                  }
                }
                ... on Content {
                  entityId
                  ...multimediaContentItemContent
                  ...multimediaPodcastContentItemContent
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );
  const multimediaItems = data.multimedia?.items?.edges ?? [];

  const handleClick = (entityId?: string, urlAlias?: string) => {
    if (!notEmpty(entityId)) return;
    sendGA4Tracking({
      action: "click",
      category: "recirculation",
      customized_parameters: {
        article_id: entityId,
        destination_url: getAbsoluteUrl(urlAlias),
        page_type: "homepage",
        widget_name: "multimedia",
      },
      subcategory: "widget",
    });
  };

  const { captureTrackImpressionEventTargetElement } =
    useTrackImpressionEventByIntersection<RecirculationWidgetImpressionEvent>({
      ga4TrackingHandler: sendGA4Tracking,
      getGa4Event: () => ({
        action: "imp",
        category: "recirculation",
        customized_parameters: {
          page_type: "homepage",
          widget_name: "multimedia",
        },
        subcategory: "widget",
      }),
      options: {
        isSendGA4Tracking: true,
        shouldSendOnce: true,
      },
    });

  const renderMultimediaItems = () =>
    multimediaItems.map(item => {
      const mainArticleType = first(item?.node?.types);
      const articleTypeName = first(mainArticleType?.value)?.name;
      const isPodcast = item?.node?.__typename === "Podcast" || articleTypeName === "Podcast";

      return isPodcast ? (
        <StyledContentItemMultimediaPodcast
          key={item?.node.entityId}
          reference={item?.node}
          onClick={handleClick}
        />
      ) : (
        <StyledContentItemMultimedia
          key={item?.node.entityId}
          reference={item?.node}
          onClick={handleClick}
        />
      );
    });

  if (multimediaItems.length === 0) return null;

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: tracking.module.Multimedia,
      }}
    >
      <Container className={className} ref={captureTrackImpressionEventTargetElement}>
        <Title as="/video" pathname="/video">
          MULTIMEDIA
        </Title>
        <Content>{renderMultimediaItems()}</Content>
      </Container>
    </BaseLinkContextProvider>
  );
};

Multimedia.displayName = "Multimedia";
