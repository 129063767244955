import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontMerriweather, fontRobotoCondensed } from "@product/scmp-sdk";

import { EntityLink } from "scmp-app/components/entity-link";

export const Container = styled.div`
  display: block;
  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;
    flex: 1;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    display: block;
    flex: initial;
  }
`;

export const Content = styled.div`
  flex: 1;
`;

export const StyledEntityLink = styled(EntityLink)`
  display: flex;

  color: #000000;
  &:hover {
    color: #2c4692;
  }
  ${props => props.theme.breakpoints.up("tablet")} {
    flex-direction: column;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    flex-direction: row;
  }
`;

export const Label = styled.div`
  margin-block-end: 8px;

  color: #4585ff;
  font-weight: 700;
  font-size: 13px;
  font-family: ${fontRobotoCondensed};
  line-height: normal;
  text-transform: uppercase;
`;

export const Headline = styled.div`
  margin-block-end: 12px;

  font-weight: 400;
  font-size: 15px;
  font-family: ${fontMerriweather};
  line-height: 21px;
`;

export type FigureProps = {
  $aspectRatio: string;
};

export const StyledFigure = styled.figure<FigureProps>`
  position: relative;

  inline-size: 86px;
  block-size: 86px;
  margin-inline-start: 12px;
  ${props => css`
    aspect-ratio: ${props.$aspectRatio};
  `}
  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 115px;
    block-size: 115px;
    margin-inline-start: 0;
  }
  ${props => props.theme.breakpoints.up("homeDesktop")} {
    inline-size: 86px;
    block-size: 86px;
    margin-inline-start: 12px;
  }
`;
