import { forwardRef } from "react";
import { graphql, useFragment } from "react-relay";

import { withHighlightedHeadline } from "scmp-app/components/content/content-item-render/highlight/hoc";
import { useContentItemProviders } from "scmp-app/components/content/content-item-render/hooks";
import type { ContentItemRenderVariantProps } from "scmp-app/components/content/content-item-render/types";
import type { commentContentItemContent$key } from "scmp-app/queries/__generated__/commentContentItemContent.graphql";

import {
  ActionBar,
  AuthorImages,
  AuthorLinks,
  Container,
  Content,
  ContentContainer,
  Headline,
  StyledEntityLink,
} from "./styles";

export type Props = {
  reference: commentContentItemContent$key;
} & ContentItemRenderVariantProps;

const Component = forwardRef<HTMLDivElement, Props>(
  ({ className, onClick, reference: reference_ }, reference) => {
    const content = useFragment(
      graphql`
        fragment commentContentItemContent on Content {
          entityId
          urlAlias
          ...entityLink
          ...hooksContentItemProvidersContent
            @arguments(
              withAuthorLink: true
              withHeadline: true
              withAuthorImage: true
              withActionBar: true
            )
        }
      `,
      reference_,
    );
    const providers = useContentItemProviders(content);

    return (
      <Container className={className} ref={reference}>
        <ContentContainer>
          <Content>
            <AuthorLinks onClick={() => onClick?.(content.entityId, content.urlAlias)}>
              {providers.author?.({ ignoreAuthorType: true, isMultiple: true })}
            </AuthorLinks>
            <StyledEntityLink
              reference={content}
              onClick={() => onClick?.(content.entityId, content.urlAlias)}
            >
              <Headline>
                {providers.headline({ hideTooltip: true, preferSocialHeadline: true })}
              </Headline>
            </StyledEntityLink>
          </Content>
          <AuthorImages>
            {providers.authorImage?.({
              ignoreAuthorType: true,
              isMultiple: true,
              withOutline: true,
            })}
          </AuthorImages>
        </ContentContainer>
        <ActionBar>
          {providers.actionBar({
            commentVariant: "reverse",
            isBookmarkVisible: false,
            isCommentsVisible: true,
            isIconVisible: false,
          })}
        </ActionBar>
      </Container>
    );
  },
);

Component.displayName = "ContentItemHomeComment";

export const ContentItemComment = withHighlightedHeadline(Component);
